/* Dashboard.css */
.DashboardBody {

  a{
    text-decoration: none;
  }
  .stat-box {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    /* Agregamos una transición suave */
    cursor: pointer;
    /* Cambia el cursor al pasar sobre la caja */
  }

  .stat-box:hover {
    transform: scale(1.05);
    /* Expandir la caja al pasar el cursor */
  }

  .total-users {
    background-color: #2196F3;
    color: white;
  }

  .total-euros {
    background-color: #FF5722;
    color: white;
  }

  .verified-users {
    background-color: #4CAF50;
    color: white;
  }

  .unverified-users {
    background-color: #FF5722;
    color: white;
  }

  .total-bolivars {
    background-color: #FFC107;
    color: white;
  }

  .stat-box h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .stat-box p {
    font-size: 1.2rem;
    font-weight: bold;
  }


  .transaction-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .transaction-table th,
  .transaction-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  .transaction-table th {
    background-color: #f9f9f9;
  }

  .approved-icon {
    color: #4CAF50;
  }

  .rejected-icon {
    color: #F44336;
  }

  .pending-icon {
    color: #FFC107;
  }

  .details-button {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }

  .details-button:hover {
    background-color: #1565C0;
  }
}