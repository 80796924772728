.AdmBody {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
  background-image: url('../Images/LoginBG.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}


.AdmBody {
  display: grid;
  place-items: center;
  margin: 0;
  font-family: "Euclid Circular A";
  animation: rotate 6s infinite alternate linear;

  a {
    text-decoration: none;
    color: #409192;
  }

  .card-adm {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    z-index: 3;
    width: 100%;
    max-width: 360px;
    /* Usa max-width para limitar el ancho de la tarjeta */
    /* Centra la tarjeta horizontalmente */
    padding: 170px 30px 54px;
    border-radius: 1.25rem;
    background: #fff;
    text-align: center;
    box-shadow: 0 100px 100px rgb(0 0 0 / 10%);
  }

  .card-admtop {
    overflow: hidden;
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: 360px;
    /* Usa max-width para limitar el ancho de la tarjeta */
    margin: 0 auto;
    /* Centra la tarjeta horizontalmente */
    padding: 100px 10px 4px;
    // border-radius: 1.25rem;
    border-radius: 2rem 2rem 0px 0px;
    background: #fff;
    text-align: center;
    box-shadow: 0 100px 100px rgb(0 0 0 / 10%);

    .parrafo-login {
      margin-bottom: 1.5em;
    }
  }


  .card-adm::before {
    content: "";
    position: absolute;
    top: -880px;
    left: 50%;
    transform: translate(-50%, 0);
    /* Usa transform en lugar de translate */
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    background: #409192;
  }

  .card-admtop::before {
    content: "";
    position: absolute;
    bottom: -880px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 900px;
    z-index: -1;
    /* Ajustar el valor del radio de borde para hacerlo menos redondeado */
    background: #409192;
    box-shadow: #2a3444;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }

  #RegisterA {
    color: #409192;
    text-decoration: none;
  }

  #RegisterA:hover {
    color: #FFC10E;
    text-decoration: none;
  }


  @media (width >=500px) {
    .card-adm {
      margin: 0;
      width: 360px;
    }
  }

  .card-adm .logo {
    position: absolute;
    top: 1em;
    left: 50%;
    translate: -50% 0;
    width: 80px;
  }

  .card-admtop .slogan {
    position: absolute;
    top: .6em;
    left: 50%;
    translate: -50% 0;
    width: 250px;
  }

  .card-adm>h2 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 30px;
    color: #2a3444;
  }

  .form {
    margin: 0 0 36px;
    display: grid;
    gap: 16px;
  }

  .form input,
  .form button {
    margin-top: 15px;
    width: 100%;
    height: 56px;
    margin-bottom: 20px;
  }

  .form input {
    margin-top: 10px;
    margin-left: 10px;
    border: none;
    border-bottom: 1px solid #000;
    background: transparent;
    outline: none;
    height: 40px;
    font-size: 16px;
    padding: 0 24px;
    color: #000;
  }

  .form>input::placeholder {
    color: #606060;
  }

  .form>button {
    cursor: pointer;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    border: none;
    background: #FFC10E;
    color: #f9f9f9;
    border-radius: 20px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    transition: all 0.375s;
  }

  .form>button:hover {
    background: #409192;

  }

  .card-adm>footer {
    color: #a1a1a1;
  }

  .card-adm>footer>a {
    color: #216ce7;
  }

  .Recover:hover {
    color: #FFC10E;
  }

}