.LoginBody {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
  background-image: url('../Images/LoginBG.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.LoginBody {
  display: grid;
  place-items: center;
  margin: 0;

  font-family: "Euclid Circular A";
  animation: rotate 6s infinite alternate linear;

  a {
    text-decoration: none;
    color: #409192;
  }

}


.LoginBody{
.card-login {
  overflow: hidden;
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 360px;
  /* Usa max-width para limitar el ancho de la tarjeta */
  margin: 0 auto;
  /* Centra la tarjeta horizontalmente */
  padding: 170px 30px 54px;
  border-radius: 0px 0px 1.25rem 1.25rem;
  background: #fff;
  text-align: center;
  box-shadow: 0 100px 100px rgb(0 0 0 / 10%);
}

.card-logintop {
  overflow: hidden;
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 360px;
  /* Usa max-width para limitar el ancho de la tarjeta */
  margin: 0 auto;
  /* Centra la tarjeta horizontalmente */
  padding: 100px 10px 4px;
  // border-radius: 1.25rem;
  border-radius: 2rem 2rem 0px 0px;
  background: #fff;
  text-align: center;
  box-shadow: 0 100px 100px rgb(0 0 0 / 10%);

  .parrafo-login{
    margin-bottom: 1.5em;
  }
}


.card-login::before {
  content: "";
  position: absolute;
  top: -880px;
  left: 50%;
  transform: translate(-50%, 0);
  /* Usa transform en lugar de translate */
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  background: #409192;
}

.card-logintop::before {
  content: "";
  position: absolute;
  bottom: -880px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 900px;
  z-index: -1;
  /* Ajustar el valor del radio de borde para hacerlo menos redondeado */
  background: #409192;
  box-shadow: #2a3444;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

#RegisterA {
  color: #409192;
  text-decoration: none;
}

#RegisterA:hover {
  color: #FFC10E;
  text-decoration: none;
}


@media (width >=500px) {
  .card-login {
    margin: 0;
    width: 360px;
  }
}

.card-login .logo {
  position: absolute;
  top: .3em;
  left: 50%;
  translate: -50% 0;
  width: 80px;
}

.card-logintop .slogan {
  position: absolute;
  top: .6em;
  left: 50%;
  translate: -50% 0;
  width: 250px;
}

.card-login>h2 {
  font-size: 22px;
  font-weight: 300;
  margin: 0 0 30px;
  color: #2a3444;
}

.form {
  margin: 0 0 36px;
  display: grid;
  gap: 16px;
}

.form {
  margin: 0 0 36px;
  display: grid;
  gap: 16px;
}

.form input,
.form button {
  margin-top: 15px;
  width: 100%;
  height: 56px;
  margin-bottom: 20px;
}

.containerCorreo[disabled],
.containerPassword[disabled] {
  background-color: gray; /* Cambia el color de fondo según tus preferencias */
  color: rgb(255, 255, 255); /* Cambia la opacidad para hacerlo más oscuro */
  cursor: not-allowed; /* Cambia el cursor para mostrar que está deshabilitado */
  
}



.form input{
  margin-top: 1px;
  margin-left: 10px;
  border: none;
  border-bottom: 1px solid #000;
  background: transparent;
  outline: none;
  height: 40px;
  font-size: 16px;
  padding: 0 24px;
  color: #000;
}

.form>input::placeholder {
  color: #606060;
}

.form>button {
  cursor: pointer;
  width: 100%;
  height: 46px;
  padding: 0 16px;
  border: none;
  background: #FFC10E;
  color: #f9f9f9;
  border-radius: 20px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 2px;
  transition: all 0.375s;
}

.form>button:hover{
 background: #409192;

}
.card-login>footer {
  color: #a1a1a1;
}

.card-login>footer>a {
  color: #216ce7;
}

.Recover:hover{
  color: #FFC10E;
}

}