.Segmento-2 {
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-transform: capitalize;
    box-sizing: border-box;
    height: 100%;
   
    font-family: 'Lato', sans-serif;


    .container_all{
        position: relative;
        right: 0;
        transition: all 300ms;
    }
    
    .move_content{
        right: 180px;
    }
    
    
    
    /****************************/
    
    
    
    
    /*INICIO DE LAS CARD*/
    
    .container__card{
        max-width: 1200px;
        padding: 20px;
        padding-bottom: 40px;
        margin: auto;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center
    }
    
    .card{
        width: 300px;
        position: relative;
        margin: 20px;
        font-family: 'Poppins', sans-serif;
        transition: all 300ms;
        border-radius: 20px;
    }
    
    .card:hover{
        box-shadow: 1px 1px 20px -10px #20202049;
    }
    
    .card:hover .info__description{
        margin-top: 0;
        border-radius: 0px 0px 20px 20px;
    
    }
    
    .card:hover .icon{
        margin-top: -110px;
        border-radius: 20px 20px 0px 0px;
    }
    
    .icon{
        width: 100%;
        height: 220px;
        background: #EFF3F5;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        transition: all 400ms cubic-bezier(0.5,0,0,1);
        border-radius: 20px;
    }
    
    .icon img{
        width: 150px;
        color: #fff;
        transition: all 400ms cubic-bezier(0.5,0,0,1);
    }
    
    .info__description{
        width: 100%;
        height: 220px;
        color: #474747;
        background: #EFF3F5;
        padding: 20px;
        margin-top: -220px;
        transition: all 400ms cubic-bezier(0.5,0,0,1);
        border-radius: 20px;
    }
    
    .info__description p{
        font-size: 14px;
        margin-top: 10px;
        display: -webkit-box;
        display: -moz-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 5;
        line-clamp: 5;
        overflow: hidden;
    }
    
    .info__description input{
        margin-top: 20px;
        padding: 10px 40px;
        background: #FFC10E;
        color: white;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        border: none;
        border-radius: 20px;
        box-shadow: 1px 1px 30px -5px #20202049;
        transition: box-shadow 300ms;
    }
    
    .info__description input:hover{
        box-shadow: 1px 1px 30px -5px #20202070;
    }
    
    
    /*COLOR DE LAS CARD*/
    
    .c1:hover .icon{
        background: #409192;
    }
    
    .c1 .icon img{
        color: #DD4B25;
    }
    
    .c1:hover .icon i{
        color: #fff;
    }
    
    
    .c2:hover .icon{
        background: #146EB0;
    }
    
    .c2 .icon i{
        color: #146EB0;
    }
    
    .c2:hover .icon i{
        color: #fff;
    }
    
    
    .c3:hover .icon{
        background: #E8B600;
    }
    
    .c3 .icon i{
        color: #E8B600;
    }
    
    .c3:hover .icon i{
        color: #fff;
    }
    
    /*FIN DE LAS CARD*/

}