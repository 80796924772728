@use 'Changes';
@use 'NavBar';
@use 'Login';
@use 'Register';
@use 'Segmento1';
@use 'Segmento2';
@use 'Segmento3';
@use 'Segmento4';
@use 'Segmento5';
@use 'Segmento6';
@use 'Footer';
@use 'Faqs';
@use 'Recover';
@use 'AdmRe';
@use 'Dashboard';
@use 'Users';
@use 'NotFound';
@use 'Currency';
@use 'Spinner';
@use 'Privacy';
@use 'FixedAlert';
@use 'Cookies';
@use 'Contact';

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: Roboto,'Noto Sans','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #FFF;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}