.RegisterBody {
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url('../Images/Register.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}


.RegisterBody {
  display: grid;
  place-items: center;
  margin: 0;

  font-family: "Euclid Circular A";
  animation: rotate 6s infinite alternate linear;

  a {
    text-decoration: none;
    color: #409192;
  }

}


.card-register {
   overflow: hidden;
    position: relative;
    margin: 0 auto;
    z-index: 3;
    width: 100%;
    max-width: 360px;
    // height: 2px;
    /* Usa max-width para limitar el ancho de la tarjeta */
    /* Centra la tarjeta horizontalmente */
    padding: 170px 30px 54px;
    border-radius: 1.25rem;
    background: #fff;
    text-align: center;
    box-shadow: 0 100px 100px rgb(0 0 0 / 10%);
}

.register-image-container {
  display: flex;
  justify-content: flex-end;
  /* Align the image to the right */
  flex-grow: 1;
  /* Take up remaining space */
  max-width: 990px;
  /* Adjust this as needed */
  margin-left: 40px;
  /* Add spacing between form and image */
}

.register-image {
  width: 320px;

}


.card-register::before {
  content: "";
  position: absolute;
  top: -880px;
  left: 50%;
  transform: translate(-50%, 0);
  /* Usa transform en lugar de translate */
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  background: #409192;
}

.card-registertop::before {
  content: "";
  position: absolute;
  bottom: -880px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 1190px;
  height: 900px;
  /* Ajustar el valor del radio de borde para hacerlo menos redondeado */
  background: #409192;
  box-shadow: #2a3444;
}


@media (width >=500px) {
  .card-register {
    margin: 0;
    width: 360px;
  }
}

.checkboxes Input {
  margin-right: 10px;
}

.card-register {
  .logo {
    position: absolute;
    top: 15px;
    left: 50%;
    translate: -50% 0;
    width: 85px;
  }


  .card-register>h2 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 30px;
    color: #2a3444;
  }


   .inputs input {

    margin-top: 10px;
    margin-left: 10px;
    border: none;
    border-bottom: 1px solid #000;
    background: transparent;
    outline: none;
    height: 40px;
    font-size: 16px;
    padding: 0 24px;
    color: #000;
    // display: flex;
    // align-items: center;
    // margin: auto;
    // height: 50px;
    // // background: #eaeaea;
    // border-radius: 6px;
    // padding-left: 15px;
  }

  .form>input::placeholder {
    color: #606060;
  }

  .form>button {
    cursor: pointer;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    border: none;
    background: #FFC10E;
    color: #f9f9f9;
    border-radius: 20px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    transition: all 0.375s;
  }

  .form>button:hover {
    background: #409192;

  }


  .card-register>footer {
    color: #a1a1a1;
  }

  .card-register>footer>a {
    color: #216ce7;
  }

}