.Segmento-4 {
 
    width: 100%;

    #hero {
        width: 100%;
        height: 100vh;
        color: #fff ;
        background: url("../Images/Segmento4.png") top center;
        background-size: cover;
        position: relative;
      }
      
      #hero:before {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
      }
      
      #hero h1 {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        text-transform: uppercase;
        color: #fff;
      }
      
      #hero h2 {
        color: #eee;
        margin-bottom: 30px;
        font-size: 24px;
      }
      
      #hero .btn-get-started {
        font-family: "Raleway", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.5px;
        display: inline-block;
        padding: 10px 28px;
        transition: 0.5s;
        border: 2px solid #fff;
        color: #fff;
      }
      
      #hero .btn-get-started:hover {
        background: #FFC10E;
        border-color: #FFC10E;
      }
      
      @media (min-width: 1024px) {
        #hero {
          background-attachment: fixed;
        }
      }
      
      @media (max-width: 768px) {
        #hero {
          text-align: center;
        }
      
        #hero .container {
          padding-top: 40px;
        }
      
        #hero h1 {
          font-size: 28px;
          line-height: 36px;
        }
      
        #hero h2 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 30px;
        }
    }
}

/* Estilos para teléfonos */
@media (max-width: 768px) {
  .Segmento-4 {
    width: 100%; /* Ocupa todo el ancho en dispositivos móviles */

    #hero {
      width: 100%;
      height: 100vh;
      text-align: center; /* Centramos el contenido en dispositivos móviles */
      background-size: cover;
      position: relative;
      background-attachment: scroll; /* Cambiamos a desplazamiento normal */
    }

    #hero:before {
      content: "";
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }

    #hero h1 {
      margin: 0 0 10px 0;
      font-size: 28px; /* Reducimos el tamaño de fuente del título */
      font-weight: 700;
      line-height: 36px;
      text-transform: uppercase;
      color: #fff;
    }

    #hero h2 {
      color: #eee;
      margin-bottom: 20px; /* Reducimos el margen inferior del subtítulo */
      font-size: 18px; /* Reducimos el tamaño de fuente del subtítulo */
      line-height: 24px;
    }

    #hero .btn-get-started {
      font-family: "Raleway", sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.5px;
      display: inline-block;
      padding: 10px 20px; /* Reducimos el espacio alrededor del botón */
      transition: 0.5s;
      border: 2px solid #fff;
      color: #fff;
      margin-bottom: 20px;
    }

    #hero .btn-get-started:hover {
      background: #FFC10E;
      border-color: #FFC10E;
    }
  }
}
