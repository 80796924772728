.privacy-main, .cookies-main {
    max-width: 900px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff; /* Fondo blanco */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    color: #333333; /* Color del texto */
  
    h1, h3, h5 {
      text-align: center;
    }
  
    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      color: #409192; /* Color principal */
      margin-bottom: 2rem;
    }
  
    h3 {
      font-size: 1.75rem;
      color: #409192; /* Color principal */
      margin-bottom: 1.5rem;
      text-align: left;
      margin-top: 2rem;
    }
  
    h5 {
      font-size: 1rem;
      font-weight: 600;
      color: #6c757d; /* Color secundario */
      margin-bottom: 2rem;
    }
  
    p {
      font-size: 1rem;
      line-height: 1.7;
      text-align: left;
      margin-bottom: 1rem;
    }
  
    ul {
      margin-left: 1.5rem;
      list-style-type: disc;
  
      li {
        margin-bottom: 0.5rem;
      }
    }
  
    a {
      color: #0056b3; /* Color de los enlaces */
      text-decoration: none;
  
      &:hover {
        color: darken(#0056b3, 10%); /* Enlace en hover */
        text-decoration: underline;
      }
    }
  
    h6 {
      font-size: 0.875rem;
      text-align: center;
      margin-top: 2rem;
      color: #6c757d; /* Color secundario */
    }
  
    // Estilos para formularios y botones
    .contact-form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 2rem;
  
      label {
        font-weight: bold;
        color: #0056b3; /* Color principal */
      }
  
      input, textarea {
        width: 100%;
        padding: 1rem;
        border: 1px solid #6c757d; /* Borde secundario */
        border-radius: 8px;
        font-size: 1rem;
  
        &:focus {
          outline: none;
          border-color: #0056b3; /* Color al enfoque */
        }
      }
  
      button {
        align-self: center;
        padding: 0.75rem 2rem;
        color: white;
        background-color: #0056b3; /* Color principal */
        border-radius: 8px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: darken(#0056b3, 10%);
        }
      }
    }
  
    // Estilos responsivos
    @media (max-width: 768px) {
      padding: 1.5rem;
  
      h1 {
        font-size: 2rem;
      }
  
      h3 {
        font-size: 1.5rem;
      }
  
      p, ul, li {
        font-size: 0.9rem;
      }
  
      button {
        padding: 0.5rem 1rem;
      }
    }
  }
  