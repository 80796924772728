/* Users.css */

.userContent {
    padding: 20px;
    margin: 0, 15px;

    .titleUser {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .search-input {
        width: 80%;
        margin-right: 10px;
    }

    .Search {
        display: flex;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .userTable {
        margin: 0 ,10px ;
        width: calc(100% - 10px);
    }

    .userTable tbody tr:hover {
        background-color: #FFC10E;
        cursor: pointer;
    }
}