/* Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner-image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .spinner-image {
    object-fit: contain;
  }
  
  @keyframes spin {
    
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  