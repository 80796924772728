footer {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(251, 159, 34, 0.8);
  padding: 20px;
  text-align: center; // Centramos el contenido en dispositivos móviles

  .footer-left {
    width: 100%; 

    p {
      font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 900;
      margin-top: 1em;
    }
  }

  .footer-right {
    margin-top: 1em;
    width: 100%;
    
    ul {
      list-style: none;
      font-size: 1rem;
      font-style: normal;
      font-weight: 900;
      display: flex;
      justify-content: center;
      gap: 20px;

      .linkFooter {
        text-decoration: none;

        li {
          color: #212121;
          font-size: 1em;
          font-weight: 700;
        }
      }
    }
  }
}
