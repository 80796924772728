.currencyContainer {
    background-color: #D1D1D1;
    height: 100vh;
  
    .currency {
        display: flex;
        flex-wrap: wrap; /* Hace que los elementos flexibles se envuelvan a la siguiente línea si no caben en una sola fila */
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        
      

      .Change-Input1 {
        width: 60%;
        position: relative;
        top: 3em;
        left: 10em;
        margin-bottom: 1em;
  
        .centered-input {
          text-align: center
        }
  
        Input {
          background-color: #fff;
          color: #212121;
          border: none;
          box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.25);
          font-size: 25px
        }
  
        Button {
          border-radius: 50px;
          background-color: #fff;
          border: none;
          box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.25);
  
          color: #212121;
          text-align: center;
          font-family: Roboto;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
  
      .changesBtn{
        position: relative;
        top: 3em;
        left: 18em;
        margin-bottom: 1em;
  
        Button {
          border-radius: 10px;
          border: none;
          margin-right: 1.3em;
          align-items: center;
          text-align: center;
          font-family: Roboto;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .input-group-container {
        width: calc(50% - 1em); /* Ajusta el ancho de cada elemento para mostrar dos en una fila */
        margin-right: 1em; /* Agrega un margen derecho para separarlos */
  
        @media (max-width: 768px) {
          width: 100%; /* Cambia a ancho completo en pantallas más pequeñas */
          margin-right: 0; /* Elimina el margen derecho en pantallas más pequeñas */
          margin-bottom: 1em; /* Agrega margen inferior para separarlos verticalmente */
        }
    }
  
    }

    .update-button {
        width: 200px; /* Ancho del botón */
        align-self: flex-end; /* Alinea el botón a la derecha */
      }
  }
  